import './style.scss'
import {Stack} from "../Stack/Stack";
import {useEffect, useRef, useState} from "react";
import CoverVideoOgv from '../../assets/video/cover-video.ogv'
import CoverVideoWebm from '../../assets/video/cover-video.webm'
import CoverVideo from '../../assets/video/cover-video.mp4'
import coverBg from '../../assets/images/cover-bg.png'
import useOnScreen from "../../hook/useOnScreen";

export const Cover = () => {

    const titleRef = useRef<HTMLHeadingElement>(null)
    const isVisibleTitle = useOnScreen(titleRef)

    const [isFixed, setIsFixed] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.innerWidth > 767) {
                setIsFixed(window.scrollY >= 101)
            } else {
                setIsFixed(window.scrollY >= 80)
            }
        })
    }, [])


    const spanRef = useRef<HTMLDivElement>(null);
    const sectionRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleScroll = () => {
            if (sectionRef.current && spanRef.current) {
                const elementTop = spanRef.current.getBoundingClientRect().top;
                const elementHeight = spanRef.current.getBoundingClientRect().height;
                const windowHeight = window.innerHeight;

                // Calculate the percentage of the element that is in view
                const scrollPosition = Math.min(windowHeight*3, Math.max(0, windowHeight - elementTop-200));
                const percentage = Math.min(100, (scrollPosition / elementHeight) * 60);
                    spanRef.current.style.backgroundPosition = `${-percentage}% 0`;

            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <section className={` cover-wrapper ${isFixed ? 'fixed-section' : ''}`} ref={sectionRef}>
        <Stack className='G-container'>
            <Stack className={'cover-section'}>
                <h1 ref={titleRef} className={isVisibleTitle ? 'show-title' : ''}>Разработка и исследование поршневых
                    двигателей</h1>
                <div className={`cover-bg ${isFixed ? 'fix-cover-image' : ''}`}
                     style={{backgroundImage: `url(${coverBg})`}}/>
            </Stack>
        </Stack>
        <Stack className={'video-block'} align={"center"}>
            <video autoPlay playsInline loop preload={'true'} muted>
                <source src={CoverVideo} type="video/mp4"/>
                <source src={CoverVideoOgv} type="video/ogv"/>
                <source src={CoverVideoWebm} type="video/webm"/>
            </video>
        </Stack>
        <Stack className={'G-container'}>
            <div className={'about-engine'}>
                <span ref={spanRef}>Создаем двигатели будущего уже сегодня. Развиваем российское производство и укрепляем
                    технологическую независимость страны</span>
            </div>
        </Stack>
    </section>
}