import './style.scss'

import {Stack} from "../Stack/Stack";
import partnerImage1 from '../../assets/images/partner-1.png'
import partnerImage2 from '../../assets/images/partner-2.svg'
import partnerImage3 from '../../assets/images/partner-3.svg'
import partnerImage4 from '../../assets/images/partner-4.png'
import partnerImage5 from '../../assets/images/partner-5.png'
import partnerImage6 from '../../assets/images/partner-6.png'
import partnerImage7 from '../../assets/images/partner-7.png'
import {useEffect, useRef} from "react";
import useOnScreen from "../../hook/useOnScreen";

import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";

export const OurPartners = () => {

    const sectionRef = useRef<HTMLDivElement>(null);
    const refSlider = useRef<Splide>(null);
    const sectionInView = useOnScreen(sectionRef)

    useEffect(() => {
        if (refSlider.current && refSlider.current.splide && sectionInView) {
            refSlider.current.splide.go(0)
        }
    }, [sectionInView])

    const images = [
        partnerImage1,
        partnerImage2,
        partnerImage7,
        partnerImage3,
        partnerImage4,
        partnerImage5,
        partnerImage6,
    ]

    return <section className={`our-partners ${sectionInView ? 'show-section' : ''}`} ref={sectionRef}>
        <Stack className={'G-container'}>
            <div className='our-partner-tittle'>
                <h3>Наши партнёры</h3>
            </div>
            <div className={'partners-slider'}>
                <Splide ref={refSlider} extensions={{AutoScroll}} options={{
                    type: 'loop',
                    drag: 'free',
                    arrows: false,
                    autoWidth: false,
                    pagination: false,
                    gap: 100,
                    autoScroll: {
                        speed: 0.8,
                    },
                    breakpoints: {
                        1200: {
                            gap: 60,
                        },
                        767: {
                            perPage: 3,
                            autoWidth: false,
                        }
                    },
                }}>
                    {images.map((item, index) => (
                        <SplideSlide key={index}>
                            <div className="partner-slider-item">
                                <img src={item} alt={`partner-${index + 1}`}/>
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
        </Stack>

    </section>
}