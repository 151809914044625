import "./style.scss";
import {MainTitle} from "../MainTitle";

import useAreaImage1 from "../../assets/images/use-area-1.png";
import useAreaImage2 from "../../assets/images/use-area-2.png";
import useAreaImage3 from "../../assets/images/use-area-3.png";
import useAreaImage4 from "../../assets/images/use-area-4.png";
import useAreaImage5 from "../../assets/images/use-area-5.png";
import useAreaImage6 from "../../assets/images/use-area-6.png";
import {IconVehicles} from "../../assets/icons/IconVehicles";
import {IconTractor} from "../../assets/icons/IconTractor";
import {IconGenerators} from "../../assets/icons/IconGenerators";
import {IconMotorcycles} from "../../assets/icons/IconMotorcycles";
import {Stack} from "../Stack/Stack";
import React, {useRef, useState} from "react";
import {IconShredders} from "../../assets/icons/IconShredders";
import {IconLawnmowers} from "../../assets/icons/IconLawnmowers";
import Slider from "react-slick";
import useOnScreen from "../../hook/useOnScreen";

interface IAreaItem {
    title: string
    description: string
    image: string
    icon: React.ReactNode,
    id: number
}

export const UseArea = () => {

    const useAreaRef = useRef<HTMLDivElement>(null)
    const isVisibleArea = useOnScreen(useAreaRef)

    let sliderRef1 = useRef<any>(null);
    let sliderRef2 = useRef<any>(null);

    const [slider1, setSlider_1] = useState(sliderRef1.current)
    const [slider2, setSlider_2] = useState(sliderRef2.current)

    const useAreaList: IAreaItem[] = [
        {
            title: 'Тракторы',
            description: 'Двигатели, работающие на дизельном топливе, широко зарекомендовали себя в тракторостроении по всему миру.',
            image: useAreaImage1,
            icon: <IconTractor/>,
            id: 1
        },
        {
            title: 'Транспортные средства',
            description: 'Широко используются в транспортных средствах – от автомобилей до лодок и вертолётов.',
            image: useAreaImage2,
            icon: <IconVehicles/>,
            id: 0
        },

        {
            title: 'Электроагрегаты',
            description: 'Отличаются богатым разнообразием мощностей и большим моторесурсом.',
            image: useAreaImage3,
            icon: <IconGenerators/>,
            id: 2

        },
        {
            title: 'Спецтехника',
            description: 'Обеспечивают стабильную и эффективную работу сельскохозяйственной, дорожной, строительной, военной и другой спецтехники.',
            image: useAreaImage4,
            icon: <IconShredders/>,
            id: 3

        },
        {
            title: 'Газонокосилки',
            description: 'Будь то ручные, самоходные или тракторные модели, позволяют легко справляться с любым объёмом работ.',
            image: useAreaImage5,
            icon: <IconLawnmowers/>,
            id: 4

        },
        {
            title: 'Мотоциклы',
            description: 'Мотоциклы с двигателем на дизельном топливе востребованы для специального применения.',
            image: useAreaImage6,
            icon: <IconMotorcycles/>,
            id: 5

        },
    ]
    const [selectImage, setSelectImage] = useState(useAreaList[0])


    const slider_1_settings = {
        slidesToShow: 6,
        arrows: false,
        vertical: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    vertical: false,
                    variableWidth: true,
                    infinite: true,
                    rows: 1,
                    focusOnSelect: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    vertical: false,
                    infinite: true,
                    rows: 1,
                    variableWidth: true,
                    focusOnSelect: true,
                }
            },
        ]
    }

    return (
        <section>
            <Stack className='G-container'>
                <Stack className={`usearea-section ${isVisibleArea ? 'show-area' : ''}`} width={"fill"} ref={useAreaRef}>
                    <MainTitle tittle="Области применения дизельного двигателя" className={'usearea-title'}/>
                    <div className={'slider-desktop-wrapper G-flex'}>
                        <div className={'slider-navigation'}>
                            {useAreaList.map((item, index) => {
                                return <div key={index}
                                            className={`slider-header-item ${selectImage.image === item.image ? 'active' : ''}`}
                                            onClick={() => {
                                                setSelectImage(item)
                                            }}>
                                    <div
                                        className={`G-align-center slider-item-mobile`}

                                    >
                                        <div className={'usearea-icon-content'}>
                                         <span className={'usearea-icon G-center'}>
                                        {item.icon}
                                    </span>
                                            <span className={'circle-select'}/>

                                        </div>
                                        <p>{item.title}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className={'slider-selected-image'}>
                            <div className={'slider-image-content G-align-end'}
                                 style={{backgroundImage: `url(${selectImage.image})`}}>
                                <p>{selectImage.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className={'slider-wrapper G-flex slider-mobile-wrapper'}>
                        <div className={'slider-header'}>
                            <Slider  {...slider_1_settings} asNavFor={slider2}
                                     ref={sliderRef1 => setSlider_1(sliderRef1)}
                                     focusOnSelect={true}
                                     arrows={false}
                            >
                                {useAreaList.map((item, index) => {
                                    return <div key={index} className={'slider-header-item'}>
                                        <div
                                            className={`G-align-center slider-item-mobile`}

                                        >
                                            <div className={'usearea-icon-content'}>
                                         <span className={'usearea-icon G-center'}>
                                        {item.icon}
                                    </span>
                                                <span className={'circle-select'}/>

                                            </div>
                                            <p>{item.title}</p>
                                        </div>
                                    </div>
                                })}

                            </Slider>
                        </div>
                        <div className={'main-slider'}>
                            <Slider asNavFor={slider1}
                                    ref={sliderRef2 => setSlider_2(sliderRef2)}
                                    focusOnSelect={true}
                                    fade
                                    arrows={false}
                            >
                                {useAreaList.map((item, index) => {
                                    return <div key={index}>
                                        <div className={'usearea-selected G-align-end'}
                                             style={{backgroundImage: `url(${item.image})`}}>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                })}
                            </Slider>
                        </div>
                    </div>
                </Stack>
            </Stack>
        </section>
    );
};
