import {Stack} from "../Stack/Stack";
import './style.scss'
import {IconLogo} from "../../assets/icons/IconLogo";
import {IconArrowSliderLeft} from "../../assets/icons/IconArrowSliderLeft";
import React from "react";

export const Footer = () => {

    const handleClick = (elementId: string) => {
        const element = document.getElementById(elementId)
        if (element) {
            let headerOffset = 100;
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    const handleScrollTop = ()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return <footer id={'contacts'}>
        <Stack className={'footer-tittle'} width={"fill"}>
            <Stack className={'G-container'} width={"fill"} justify={"space-between"} align={"center"} direction={"row"}>
                <h3>
                    Контакты
                </h3>
                <Stack className={'go-top-content'} direction={"row"} align={"center"} gap={30} onClick={handleScrollTop}>
                    <span><IconArrowSliderLeft size={20}/></span>
                </Stack>
            </Stack>

        </Stack>
        <Stack className={'footer-information'}>
            <Stack className={'G-container'} width={"fill"}>
                <Stack className={'footer-information-content'} direction={"row"} gap={20} justify={"space-between"}
                       width={"fill"} align={"end"}>
                    <Stack className={'footer-menu'}>
                        <ul>
                            <li onClick={() => {
                                handleClick('projects')
                            }}>Проекты
                            </li>
                            <li onClick={() => {
                                handleClick('advantages')
                            }}>Преимущества
                            </li>
                            <li onClick={() => {
                                handleClick('about-us')
                            }}>О компании
                            </li>
                            <li onClick={() => {
                                handleClick('our-team')
                            }}>Команда
                            </li>
                        </ul>
                    </Stack>
                    <Stack className={'footer-address'}>
                        <span>Офис</span>
                        <p>143420, Московская обл., г.о. Красногорск, п. Архангельское, ГСК «Икар», пом. 209</p>
                        <span className={'footer-address-title'}>График работы</span>
                        <p>Ежедневно с 8:00 до 20:00</p>
                    </Stack>
                    <Stack className={'footer-contact'} justify={"end"} align={"end"}>
                        <Stack className={'go-top-content'} direction={"row"} align={"center"} gap={30} onClick={handleScrollTop}>
                            <p>Наверх</p>
                            <span><IconArrowSliderLeft size={20}/></span>
                        </Stack>
                        <a href="tel:8 (495) 799 35 33">8 (495) 799 35 33</a>
                        <a href="tel:8 (903) 799 35 33">8 (903) 799 35 33</a>
                        <a href="mailTo:info@mbv-rus.ru">info@mbv-rus.ru</a>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
        <Stack className={'footer-end'} width={"fill"}>
            <Stack className={'G-container'} width={"fill"}>
                <div className={'footer-end-content'}>
                    <Stack className={'footer-left'} direction={"row"} align={"center"} gap={55}>
                        <div onClick={handleScrollTop}>
                            <IconLogo size={42}/>
                        </div>
                        <h3>2024, Все права защищены</h3>
                    </Stack>
                    <Stack className={'footer-right'} direction={"row"} align={"center"} gap={8}>
                        <h3>сайт разработан</h3>
                        <a href={'https://formularf.ru/'} target="_blank" rel="noreferrer">ƒ∅rmuλa</a>
                    </Stack>
                </div>
            </Stack>
        </Stack>
    </footer>
}