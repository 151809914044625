import "./style.scss";

import {Stack} from "../Stack/Stack";
import {MainTitle} from "../MainTitle";
import personalSettingsImage1 from '../../assets/images/personal-settings-1.png'
import personalSettingsImage2 from '../../assets/images/personal-settings-2.png'
import personalSettingsImage3 from '../../assets/images/personal-settings-3.png'
import personalSettingsImage4 from '../../assets/images/personal-settings-4.png'
import personalSettingsImage5 from '../../assets/images/personal-settings-5.png'
import personalSettingsImage6 from '../../assets/images/personal-settings-6.png'
import engine_1 from '../../assets/images/engine-1.jpeg'
import engine_2 from '../../assets/images/engine-2.jpeg'
import engine_3 from '../../assets/images/engine-3.jpeg'
import engine_4 from '../../assets/images/engine-4.jpeg'
import engine_5 from '../../assets/images/engine-5.jpeg'
import engine_6 from '../../assets/images/engine-6.jpeg'
import engine_7 from '../../assets/images/engine-7.jpeg'
import engine_8 from '../../assets/images/engine-8.jpeg'
import moto_1 from '../../assets/images/moto-1.jpeg'
import moto_2 from '../../assets/images/moto-2.jpeg'
import moto_3 from '../../assets/images/moto-3.jpeg'
import moto_4 from '../../assets/images/moto-4.jpeg'
import moto_5 from '../../assets/images/moto-5.jpeg'
import controlBlockImage from '../../assets/images/control-block.jpg'

import {EngineCard} from "../EngineCard/EngineCard";
import React, {useEffect, useRef, useState} from "react";
import {IconClose} from "../../assets/icons/IconClose";
import Slider from "react-slick";
import EmptyModal from "../EmptyModal/EmptyModal";
import {SwapMobile} from "../SwapMobile/SwapMobile";

export const PersonalSettings = () => {
    const [openModal, setOpenModal] = useState(false)
    const [selectedInfo, setSelectedInfo] = useState<number | null>(null)
    const closeModal = () => {
        document.body.classList.remove('G-hidden')
        setOpenModal(false)
        setSelectedInfo(null)
    }

    const openModalInfo = (index: number) => {
        document.body.classList.add('G-hidden')
        setOpenModal(true)
        setSelectedInfo(index)
    }

    useEffect(() => {
        window.addEventListener('keydown', closeModalByEsc)
        return () => {
            window.removeEventListener('keydown', closeModalByEsc)
        }
        // eslint-disable-next-line
    }, [])

    function closeModalByEsc(e: KeyboardEvent) {
        if (e.key === 'Escape') {
            closeModal()
        }
    }

    return (
        <>
            <section className={'personal-settings-section'} id={'projects'}>
                <Stack className={'G-container'}>
                    <MainTitle tittle='Наши разработки'/>
                    <div className={'personal-settings-list G-flex-wrap'}>

                        <EngineCard className={'engine-card-1'} item={{
                            title: 'Одноцилиндровый дизельный двигатель',
                            description: 'Опытный образец одноцилиндрового дизельного двигателя 1Д35',
                            image: personalSettingsImage1,
                        }} onClick={() => {
                            openModalInfo(0)
                        }}/>
                        <EngineCard className={'engine-card-6'} item={{
                            title: 'Двухцилиндровый дизельный двигатель',
                            description: 'Опытный образец двухцилиндрового дизельного двигателя 2Д40',
                            image: personalSettingsImage6,
                        }} onClick={() => {
                            openModalInfo(5)
                        }}/>
                        <EngineCard className={'engine-card-3'} item={{
                            title: 'Блок управления',
                            description: 'Опытный образец электронного блока управления дизельным двигателем ',
                            image: personalSettingsImage3,
                        }} onClick={() => {
                            openModalInfo(2)
                        }}/>
                        <EngineCard className={'engine-card-5'} item={{
                            title: 'Мобильная тактическая электростанция',
                            description: 'Мобильная тактическая электростанция МБВ-354',
                            image: personalSettingsImage5,
                        }} onClick={() => {
                            openModalInfo(4)
                        }}/>
                        <EngineCard className={'engine-card-2'} item={{
                            title: 'Кросс-эндуро мотоцикл',
                            description: 'Разработка и изготовление опытного образца кросс-эндуро мотоцикла с электрической трансмиссией и ременной передачей',
                            image: personalSettingsImage2,
                        }} onClick={() => {
                            openModalInfo(1)
                        }}/>

                        <EngineCard className={'engine-card-4'} item={{
                            title: 'Подвеска',
                            description: 'Пневматическая подвеска для прицепов-автодомов',
                            image: personalSettingsImage4,
                        }} onClick={() => {
                            openModalInfo(3)
                        }}/>



                    </div>
                </Stack>

            </section>
            <SwapMobile disableClick={true} onSwipe={(e: any, option: string) => {
                if (option === 'right') {
                    closeModal()
                }
            }}>
                <div className={`personal-settings-modal ${openModal ? 'open-modal' : ''}`}

                >
                    <div className={'modal-bg'} onClick={closeModal}/>
                    <div className={'personal-settings-modal-content'}>
                        <div className={'personal-settings-modal-header G-justify-end'}>
                    <span className={'G-pointer'} onClick={closeModal}>
                        <IconClose/>
                    </span>
                        </div>
                        <div className='personal-settings-modal-body'>
                            {selectedInfo === 0 && <InformationCardEngine/>}
                            {selectedInfo === 1 && <InformationCardMotorcycle/>}
                            {selectedInfo === 2 && <InformationCardControlBlock/>}
                            {selectedInfo === 3 && <InformationCardSuspension/>}
                            {selectedInfo === 4 && <InformationCardPowerPlant/>}
                            {selectedInfo === 5 && <InformationCardPowerPlantMain/>}
                        </div>
                    </div>
                </div>
            </SwapMobile>
        </>
    );
};


const InformationCardEngine = () => {

    const images = [engine_1, engine_2, engine_3, engine_4, engine_5, engine_6, engine_7, engine_8]
    const [isOpen, setIsOpen] = useState(false)
    const sliderRef = useRef<Slider>(null)
    const [sliderIndex, setIndex] = useState(0)

    useEffect(() => {
        if (isOpen && sliderRef.current) {
            setTimeout(() => {
                sliderRef.current && sliderRef.current.slickGoTo(sliderIndex)
            }, 0)
        }
    }, [isOpen, sliderIndex])

    const settings = {
        slidesToShow: 5,
        arrows: true,
        dots: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 2800,
                settings: {
                    slidesToShow: 4,
                    dots: true,
                }
            },
            {
                breakpoint: 2200,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                }
            }, {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                }
            },
        ]
    }

    return <>
        <Stack className={'modal-information'}>
            <h3>Дизельный двигатель</h3>

            <div className='modal-images ' onTouchMove={(e) => {
                e.stopPropagation()
            }}>
                <Slider {...settings} >
                    {images.map((item, index) => {
                        return <div className={'modal-slide-image'} key={index}>
                            <ImageCard image={item} onClick={() => {
                                setIsOpen(true)
                                setIndex(index)
                            }}/>
                        </div>
                    })}
                </Slider>
            </div>

            <p>В 2023 году мы начали разрабатывать 1Д35 по заказу АО «НПФ «Сигма». Уже в апреле 2024 года приступили к
                конструкторским испытаниям. Этот двигатель станет полноценной заменой импортных малых индустриальных
                двигателей воздушного охлаждения.</p>
            <p>Преимущества: простая и надежная конструкция, инновационные технические решения, удобство в
                эксплуатации.</p>
            <h4>Характеристики:</h4>
            <ul>
                <li>- масса менее 40 кг, Д*Ш*В: 299*370*431</li>
                <li>- полная мощность двигателя: 6,6 кВт (9,0 л.с.) при 3 600 об/мин</li>
                <li>- диаметр цилиндра – 82мм</li>
                <li>- ход поршня – 69мм</li>
            </ul>
            <h4>Особенности конструкции двигателя:</h4>
            <p>Один кулачок распределительного вала управляет газораспределением и подачей топлива. Масляный насос
                использует одну собственную шестерню и шестерню привода распределительного вала.</p>
            <p>Разработка технологического процесса подразумевает серийную технологию уже на стадии опытного образца.
                Это
                позволяет более плавно перейти к серийному выпуску изделия.
                Более 90% составных частей двигателя – детали собственного производства, полученные методом литья,
                штамповки, гибки и ковки. Мы применяем механическую обработку, сварку, аддитивные технологии.
                В конструкции применяются комплектующие, выпускаемые отечественной промышленностью.</p>
            <p>Двигатель нацелен на применение в электроагрегатах, насосных агрегатах и подобных устройствах, в
                тракторах,
                дорожно-строительной технике и везде, где требуется компактный источник механической энергии.</p>

        </Stack>
        <EmptyModal isOpenModal={isOpen} close={() => {
            setIsOpen(false)
        }}>
            <div className='popup-slider-wrapper' onTouchMove={(e) => {
                e.stopPropagation()
            }}>
                <Slider slidesToShow={1} arrows infinite
                        ref={sliderRef}
                >
                    {images.map((item, index) => {
                        return <div className={'modal-slide-image'} key={index}>
                            <ImageCard isSub image={item}/>
                        </div>
                    })}
                </Slider>
            </div>
        </EmptyModal>
    </>
}
const InformationCardMotorcycle = () => {

    const images = [moto_1, moto_2, moto_3, moto_4, moto_5]
    const sliderRef = useRef<Slider>(null)
    const [sliderIndex, setIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (isOpen && sliderRef.current) {
            setTimeout(() => {
                sliderRef.current && sliderRef.current.slickGoTo(sliderIndex)
            }, 0)
        }
    }, [isOpen, sliderIndex])

    const settings = {
        slidesToShow: 5,
        arrows: true,
        dots: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 2800,
                settings: {
                    slidesToShow: 4,
                    dots: true,
                }
            },
            {
                breakpoint: 2200,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                }
            }, {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                }
            },
        ]
    }
    return <>
        <Stack className={'modal-information'}>
            <h3>Кросс-эндуро мотоцикл</h3>
            <div className='modal-images ' onTouchMove={(e) => {
                e.stopPropagation()
            }}>

                <Slider {...settings} >
                    {images.map((item, index) => {
                        return <div className={'modal-slide-image'} key={index}>
                            <ImageCard image={item} onClick={() => {
                                setIsOpen(true)
                                setIndex(index)
                            }}/>
                        </div>
                    })}
                </Slider>
            </div>
            <p>Мы создали легкое мощное электрическое двухколесное шасси с минималистичным дизайном, хорошими ходовыми
                качествами для езды по грунтовым проселочным дорогам и лесным тропам.</p>
            <p>Особенность изделия – отсутствие шума, стабильный быстрый разгон и возможность использования в заповедных
                и
                парковых зонах, а также в крытых зимних ангарах для тренировок.</p>
            <p>За основу взяли конструкцию с треугольной замкнутой рамой из алюминиевой трубы.
                Передняя подвеска телескопическая, перевернутого типа. Задняя подвеска маятниковая с
                моноамортизатором</p>
            <h4>Характеристики:</h4>
            <ul>
                <li>- заднее колесо – 15 дюймов</li>
                <li>- переднее колесо – 17 дюймов</li>
                <li>- тормоза дисковые</li>
                <li>- масса – 40 кг</li>
                <li>- запас хода – до 70 км</li>
            </ul>

            <p>Электрический двигатель на 4 кВт передает крутящий момент на заднее колесо при помощи зубчатого ремня и
                работает в паре с 72-вольтовым литий-ионным аккумулятором. </p>
            <p>Мощность позволяет мотоциклу двигаться по асфальту со скоростью 70 км/час. Огромный зубчатый венец на
                заднем
                колесе позволяет иметь достаточно крутящего момента для поднятия на гору под углом до 45 градусов.</p>
            <p>Электромотоцикл прошел ряд испытаний в реальных условиях.</p>
            <p>Мотоцикл строился для собственных нужд, однако у него большой потенциал для использования на благо
                общества.
                С привлечением инвестиций мы готовы развивать и поддерживать этот проект в будущем.</p>

        </Stack>
        <EmptyModal isOpenModal={isOpen} close={() => {
            setIsOpen(false)
        }}>
            <div className='popup-slider-wrapper' onTouchMove={(e) => {
                e.stopPropagation()
            }}>
                <Slider slidesToShow={1} arrows infinite
                        ref={sliderRef}
                >
                    {images.map((item, index) => {
                        return <div className={'modal-slide-image'} key={index}>
                            <ImageCard isSub image={item}/>
                        </div>
                    })}
                </Slider>
            </div>
        </EmptyModal>
    </>
}
const InformationCardControlBlock = () => {
    const images = [controlBlockImage]
    const sliderRef = useRef<Slider>(null)
    const [sliderIndex, setIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (isOpen && sliderRef.current) {
            setTimeout(() => {
                sliderRef.current && sliderRef.current.slickGoTo(sliderIndex)
            }, 0)
        }
    }, [isOpen, sliderIndex])

    return <>
        <Stack className={'modal-information'}>
            <h3>Блок управления</h3>
            <div className='modal-images ' onTouchMove={(e) => {
                e.stopPropagation()
            }}>
                <ImageCard image={controlBlockImage} onClick={() => {
                    setIsOpen(true)
                    setIndex(0)
                }}/>
            </div>
            <p>Электронный блок управления (ЭБУ) разрабатывался по заказу АО «НПФ «Сигма». ЭБУ позволяет управлять
                дизельным
                двигателем с количеством цилиндров от 1 до 4 в составе электроагрегата с применением электромеханических
                форсунок и топливного насоса типа BOSCH 3-го поколения.</p>
            <p>Блок улучшит потребительские и функциональные качества электроагрегатов. В 2022 году ЭБУ успешно прошел
                испытания на одноцилиндровом макете дизельного двигателя.</p>
        </Stack>
        <EmptyModal isOpenModal={isOpen} close={() => {
            setIsOpen(false)
        }}>
            <div className='popup-slider-wrapper' onTouchMove={(e) => {
                e.stopPropagation()
            }}>
                <Slider slidesToShow={1} arrows infinite
                        ref={sliderRef}
                >
                    {images.map((item, index) => {
                        return <div className={'modal-slide-image'} key={index}>
                            <ImageCard isSub image={item}/>
                        </div>
                    })}
                </Slider>
            </div>
        </EmptyModal>
    </>
}
const InformationCardSuspension = () => {
    return <Stack className={'modal-information'}>
        <h3>Подвеска</h3>
        <div className={'modal-video'}>
            <iframe src="https://www.youtube.com/embed/Tk7PaDYaJtM"
                    title="Пневму в каждый дом! АВТО-ДОМ.ПОДВЕСКА"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
        <p>На прицепе-автодоме можно добраться до самых красивых мест и побыть наедине с природой. Но чаще всего хорошую
            дорогу там не встретишь. Поэтому так важно подобрать правильное шасси. Нашей команде удалось создать
            конструкцию независимой пневматической подвески с упругими элементами баллонного типа и встроенными в них
            направляющими устройствами.</p>
        <p>Преимущества: проходимость, плавный ход, сохраняет в целости мебель и конструкцию прицепа.</p>
        <p>Прицеп-автодом с нашей подвеской в течение нескольких сезонов проходил реальные испытания по дороге из Москвы
            в Крым и обратно. В качестве тягача применялся автомобиль повышенной проходимости, также с пневмоподвеской
            Land Rover Discovery4.</p>
    </Stack>
}


export const InformationCardPowerPlant = () => {
    return <Stack className={'modal-information'}>
        <h3>Мобильная тактическая электростанция МБВ-354</h3>
        <p>Мобильная тактическая электростанция (МТЭ) МБВ-354 предназначена для энергоснабжения потребителей переменного
            тока с номинальным напряжением 230 В и номинальной мощностью до 2,5 кВт как на стоянке, так и в
            движении. </p>
        <p>Движение МТЭ осуществляется от одного или двух (зависит от модификации) электродвигателей постоянного тока с
            номинальным напряжением системы 72 В. Энергоснабжение электродвигателей осуществляется от АКБ ёмкостью 12
            А*ч, заряжаемой во время работы силовой установки. При движении с заглушенной силовой установкой (тихий
            режим), запас хода составляет 10 км. </p>
        <p>МБВ-354 способна двигаться как по дорогам с различным покрытием, так и без него, а также по пересеченной
            местности, преодолевая броды глубиной до 0,4 м. </p>
        <p>МБВ-354 является универсальной платформой, способной везти груз общей массой до 200 кг (для этого
            предусмотрены багажные платформы), заряжать различные устройства (для этого предусмотрен влаго- и
            пылезащищённый кейс), а также доставлять двух бойцов к месту дислокации. </p>
        <h5>Силовая установка:</h5>
        <p>Гибридная силовая установка на основе двигателя дизельного, четырехтактного, одноцилиндрового, с
            принудительной системой охлаждения (1Д35) и генератора на постоянных магнитах с контроллером постоянного
            тока с номинальным выходным напряжением 72 В; максимальная электрическая мощность силовой установки 3
            кВт</p>
        <h5>Трансмиссия</h5>
        <p>Синхронный электродвигатель на постоянных магнитах номинальной мощностью 3 кВт с приводом на заднее колесо
            двухступенчатым цепным редуктором;
            Передний привод осуществляется посредством мотор-колеса мощностью 1,5 кВт (опционально)</p>
        <h4>Характеристики:</h4>
        <ul>
            <li>- Аккумулятор – Li-ion, 72 В, 12 Ач</li>
            <li>- Запас хода без применения силовой установки (тихий режим), км – 10</li>
            <li>- Напряжение бортовой сети, В - 72</li>
            <li>- Мощность силовой установки, кВт - 3</li>
        </ul>

        <h4>Характеристики сети внешнего потребителя:</h4>
        <ul>
            <li>- Напряжение, В - 230</li>
            <li>- Частота, Гц - 50</li>
            <li>- Номинальная мощность, кВт - 2,5</li>
            <li>- Система запуска - Генератор-стартер</li>
            <li>- Топливо - Дизельное / Бензин (в зависимости от комплектации)</li>
            <li>- Максимальная скорость, км/ч - 60</li>
            <li>- Расход топлива, л/ч - 1</li>
            <li>- Сухая масса, кг - 100</li>
            <li>- Габариты, ДШВ, мм - 1870х800х1110</li>
            <li>- Передняя подвеска, ход, мм - Маятниковая, 90</li>
            <li>- Задняя подвеска, ход, мм - Маятниковая, 85</li>
            <li>- Емкость топливного бака, л - 5</li>
            <li>- Размер шин перед/зад - 24х8-12/24х8-12</li>
            <li>- Допустимая нагрузка, кг - 200</li>
        </ul>
    </Stack>
}


export const InformationCardPowerPlantMain = () => {
    return <Stack className={'modal-information'}>
        <h3>Двухцилиндровый дизельный двигатель 2Д40</h3>
        <p>В 2024 году мы начали разработку двухцилидрового дизельного двигателя 2Д40. Данный проект является логическим
            продолжением работы по отечественной замене импортных малых индустриальных двигателей. </p>
        <h4>Характеристики:</h4>
        <ul>
            <li>- Тип двигателя - четырёхтактный дизельный</li>
            <li>- Количество цилиндров - 2</li>
            <li>- Масса двигателя - 65 кг</li>
            <li>- Диаметр цилиндра - 82х71мм</li>
            <li>- Полная мощность двигателя - 14,1 кВт (19 л.с.)</li>
            <li>- Рабочий объём двигателя -  0,749 л</li>
            <li>- Частота вращения коленчатого вала при полной мощности - 60 с-1 (3600 об/мин)</li>
            <li>- Расход топлива на минимально устойчивой частоте вращения на холостом ходу- 0,25 кг/(кВт*ч)</li>
        </ul>
        <p>На текущий момент создан макетный образец 2Д40, который был представлен на форуме Армия-2024.</p>
        <p>Дальнейшие мероприятия по созданию опытных образцов 2Д40:</p>
        <ul>
            <li>- Проведение конструкторских испытаний макета и доработка конструкции </li>
            <li>- Разработка конструкторской документации</li>
            <li>- Изготовление опытных образцов</li>
            <li>- Обкатка и доводка опытных образцов</li>
        </ul>
    </Stack>
}

const ImageCard = ({onClick, image, isSub}: {
                       onClick?(): void,
                       image: string,
                       isSub?: boolean
                   }
) => {
    return isSub ? <div className={'slider-popup G-center'}>
            <img src={image} alt="personal-settings"/>
        </div> :
        <div onClick={onClick} className={'modal-image-item'} style={{backgroundImage: `url('${image}')`}}/>
}

