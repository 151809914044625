import React from "react";
import "./assets/styles/index.scss";

import {Header} from "./components/Header/Header";
import {Cover} from "./components/Cover/Cover";
import {AboutUs} from "./components/AboutUs";
import {UseArea} from "./components/UseArea";
import {PersonalSettings} from "./components/PersonalSettings/PersonalSettings";
import {WorkshopBrothers} from "./components/WorkshopBrothers/WorkshopBrothers";
import {OurPartners} from "./components/OurPartners/OurPartners";
import {OurTeam} from "./components/OurTeam/OurTeam";
import {Footer} from "./components/Footer/Footer";

function App() {
    return (
        <>
            <Header/>
            <Cover/>
            <PersonalSettings/>
            <UseArea/>
            <AboutUs/>
            <WorkshopBrothers/>
            <OurPartners/>
            <OurTeam/>
            <Footer/>
        </>
    );
}

export default App;
