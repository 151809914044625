import React from "react";


function touchX(event: any) {
    if (event.type.indexOf('mouse') !== -1) {
        return event.clientX;
    }
    return event.touches[0].clientX;
}

function touchY(event: any) {
    if (event.type.indexOf('mouse') !== -1) {
        return event.clientY;
    }
    return event.touches[0].clientY;
}

interface IProps {
    children: React.ReactNode,
    tapTolerance?: number,
    swipeTolerance?: number,
    touchClass?: string
    disableClick?: boolean

    onTap?(e: any): void,

    onSwipe?(e: any, option: string): void,
}

export const SwapMobile = ({
                               disableClick,
                               children = false,
                               tapTolerance = 10,
                               swipeTolerance = 50,
                               touchClass = '',
                               onTap,
                               onSwipe
                           }: IProps) => {

    let touchStarted = false;
    let touchMoved = false;
    let swipeOutBounded = false;
    let lastTouchStartTime = 0;
    let lastTouchEndTime = 0;

    let startX = 0;
    let startY = 0;
    let currentX = 0;
    let currentY = 0;

    let el: HTMLDivElement | null = null;


    const touchStartEvent = (event: React.TouchEvent) => {
        const isTouchEvent = event.type.indexOf('touch') >= 0;
        const isMouseEvent = event.type.indexOf('mouse') >= 0;

        if (isTouchEvent) {
            lastTouchStartTime = event.timeStamp;
        }

        if (isMouseEvent && lastTouchStartTime && event.timeStamp - lastTouchStartTime < 350) {
            return;
        }

        if (touchStarted) {
            return;
        }

        addTouchClass();

        touchStarted = true;
        touchMoved = false;
        swipeOutBounded = false;

        startX = touchX(event);
        startY = touchY(event);
        currentX = currentY = 0;
    }

    const addTouchClass = () => {
        if (el) {
            const className = touchClass;
            className && el.classList.add(className);
        }
    }

    const removeTouchClass = () => {
        if (el) {
            const className = touchClass;
            className && el.classList.remove(className);
        }
    }


    const touchMoveEvent = (event: any) => {
        currentX = touchX(event);
        currentY = touchY(event);

        if (!touchMoved) {

            touchMoved = Math.abs(startX - currentX) > tapTolerance ||
                Math.abs(startY - currentY) > tapTolerance;

        } else if (!swipeOutBounded) {
            let swipeOutBoundedData = swipeTolerance;

            swipeOutBounded = Math.abs(startX - currentX) > swipeOutBoundedData &&
                Math.abs(startY - currentY) > swipeOutBoundedData;
        }
    }

    const touchCancelEvent = () => {
        removeTouchClass();

        touchStarted = touchMoved = false;
        startX = startY = 0;
    }

    const mouseEnterEvent = () => {
        addTouchClass()
    }

    const mouseLeaveEvent = () => {
        removeTouchClass()
    }


    const touchEndEvent = (event: any) => {
        const isTouchEvent = event.type.indexOf('touch') >= 0;
        const isMouseEvent = event.type.indexOf('mouse') >= 0;

        if (isTouchEvent) {
            lastTouchEndTime = event.timeStamp;
        }

        touchStarted = false;

        removeTouchClass();

        if (isMouseEvent && lastTouchEndTime && event.timeStamp - lastTouchEndTime < 350) {
            return;
        }

        if (!touchMoved) {
            triggerEvent(event, 'tap')

        } else if (!swipeOutBounded) {
            const swipeOutBounded = swipeTolerance;
            let direction;

            if (Math.abs(startX - currentX) < swipeOutBounded) {
                direction = startY > currentY ? 'top' : 'bottom';

            } else {
                direction = startX > currentX ? 'left' : 'right';
            }

            triggerEvent(event, 'swipe', direction)
        }
    }

    const triggerEvent = (event: any, eventType: string, param?: any) => {
        switch (eventType) {
            case 'tap':
                onTap && typeof onTap === 'function' && onTap(event);
                break;

            case 'swipe':
                onSwipe && typeof onSwipe === 'function' && onSwipe(event, param);
        }
    }

    const setElRef = (element: HTMLDivElement | null) => {
        el = element
    }

    const props:any = {
        ref: setElRef,
        onTouchStart: touchStartEvent,
        onTouchMove: touchMoveEvent,
        onTouchCancel: touchCancelEvent,
        onTouchEnd: touchEndEvent,
    }

    if (!disableClick) {
        props.onMouseDown = touchStartEvent;
        props.onMouseMove = touchMoveEvent;
        props.onMouseUp = touchEndEvent;
        props.onMouseEnter = mouseEnterEvent;
        props.onMouseLeave = mouseLeaveEvent;
    }
    return React.cloneElement(children as any, props)
}


