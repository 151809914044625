import './style.scss'
import {IconLogo} from "../../assets/icons/IconLogo";
import brothersImage from '../../assets/images/brothers.png'
import brothersImage2 from '../../assets/images/brothers-2.png'
import {Stack} from "../Stack/Stack";
import Slider from "react-slick";
import {useEffect, useRef} from "react";
import useOnScreen from "../../hook/useOnScreen";

export const WorkshopBrothers = () => {
    const sliderRef = useRef<Slider>(null);
    const sectionRef = useRef<HTMLDivElement>(null)
    const sliderShowRef = useRef<HTMLDivElement>(null)
    const isVisibleSection = useOnScreen(sectionRef)
    const isVisibleSlider = useOnScreen(sliderShowRef)

    useEffect(() => {
        if (sliderRef.current && !isVisibleSlider) {
            setTimeout(() => {
                if (sliderRef.current) {
                    sliderRef.current.slickPause()
                    sliderRef.current.slickGoTo(0)
                }
            }, 100)
        }
        if (isVisibleSlider && sliderRef.current) {
            setTimeout(() => {
                if (sliderRef.current) {
                    sliderRef.current.slickPlay()
                }
            }, 100)
        }
    }, [isVisibleSlider])

    let settings = {
        infinite: true,
        autoplay: false,
        speed: 800,
        dots:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade:true
    };


    return <section className={`workshop-section G-flex ${isVisibleSection ? 'show-section' : ''}`} id={'about-us'}
                    ref={sectionRef}>
        <Stack className={'G-container'}>
            <div className='workshop-section-wrapper G-flex G-justify-end G-align-end'>
                <div className='workshop-section-users' ref={sliderShowRef}>
                    <Slider {...settings}
                            ref={sliderRef}>
                        <div className='workshop-slideritem'>
                           <div className={'G-flex G-center workshop-images'}>
                               <img src={brothersImage2} alt="brothers"/>
                           </div>
                        </div>
                        <div className='workshop-slideritem'>
                            <div className={'G-flex G-center workshop-images'}>
                                <img src={brothersImage} alt="brothers"/>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div className={'workshop-section-info'}>
                    <div className='workers-image'>
                        <IconLogo size={95}/>
                    </div>
                    <h3>Мастерская братьев Вороновых</h3>
                    <p>Мы братья Алексей и Александр Вороновы. С юных лет мы мечтали создавать технику, сочетающую в
                        себе удобство, надёжность и пользу для общества. Для нас качество – это не просто слова, а
                        принципы, воплощенные в металле. В 2021 году мы открыли свою мастерскую
                        и собрали команду талантливых и увлечённых специалистов.
                    </p>
                    <p> В своей работе делаем ставку на новейшие технологии. Уже на этапе разработки конструкция опытных
                        образцов ориентирована на серийное изготовление высококачественной продукции по доступной
                        цене.</p>
                </div>
            </div>

        </Stack>
    </section>
}
