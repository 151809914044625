import React, {FC, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import './style.scss'
import {CSSTransition} from "react-transition-group";
import {IconClose} from "../../assets/icons/IconClose";

interface IModal {
    isOpenModal: boolean,
    customClass?: string,

    close(): void,

    children: React.ReactNode
}

const EmptyModal: FC<IModal> = ({customClass = '', isOpenModal = false, close, children}) => {
    const modalRef = useRef<HTMLDivElement>(null)


    useEffect(() => {
        window.addEventListener('keydown', closeModalByEsc)
        return () => {
            window.removeEventListener('keydown', closeModalByEsc)
        }
        // eslint-disable-next-line
    }, [])

    function closeModalByEsc(e: KeyboardEvent) {
        if (e.key === 'Escape') {
            close()
            e.stopPropagation()
        }
    }

    return ReactDOM.createPortal(
        <CSSTransition unmountOnExit={true} in={isOpenModal} timeout={400} classNames='css-transition'
                       onExited={() => {
                           document.body.classList.remove('G-hidden')
                       }}
                       onEnter={() => {
                           document.body.classList.add('G-hidden')
                       }}
                       nodeRef={modalRef}>
            <div className={`G-empty-modal-container ${customClass}`} ref={modalRef}>
                <div className={'modal-bg'} onClick={close}/>
                <div className={'G-modal-content'}>
                 <span onClick={close} className={'G-pointer empty-modal-close'}>
                        <IconClose/>
                    </span>
                    {children}
                </div>
            </div>
        </CSSTransition>
        , document.getElementById('modal') as Element
    )
}

export default EmptyModal


