import {Stack} from "../Stack/Stack";
import './style.scss'
import teamMemberImage1 from '../../assets/images/team-member-1.png'
import teamMemberImage2 from '../../assets/images/team-member-2.png'
import teamMemberImage3 from '../../assets/images/team-member-3.png'
import teamMemberImage4 from '../../assets/images/team-member-4.png'
import teamMemberImage5 from '../../assets/images/team-member-5.png'
import teamMemberImage6 from '../../assets/images/team-member-6.png'
import teamMemberImage7 from '../../assets/images/team-member-7.png'
import Slider from "react-slick";
import {IconArrowSliderRight} from "../../assets/icons/IconArrowSliderRight";
import {IconArrowSliderLeft} from "../../assets/icons/IconArrowSliderLeft";
import {useRef, useState} from "react";
import useOnScreen from "../../hook/useOnScreen";

interface ITeamItem {
    name: string,
    position: string,
    image: string
}

export const OurTeam = () => {
    const sectionRef = useRef<HTMLDivElement>(null)
    const isVisibleSection = useOnScreen(sectionRef)

    const slider = useRef<Slider>(null);
    const [activeSlider, setActiveSlider] = useState(1)
    const ourTeamList: ITeamItem[] = [{
        name: 'Алексей Воронов',
        position: 'Генеральный директор',
        image: teamMemberImage1
    },
        {
            name: 'Александр Воронов',
            position: 'Главный конструктор',
            image: teamMemberImage2
        },
        {
            name: 'Галина Воронова',
            position: 'Директор по стратегическому развитию',
            image: teamMemberImage3
        },
        {
            name: 'Андрей Калистратов',
            position: 'Директор по закупкам и логистике',
            image: teamMemberImage4
        },
        {
            name: 'Константин Лукашик',
            position: 'Главный технолог литейного производства',
            image: teamMemberImage5
        },
        {
            name: 'Полина Аненкова',
            position: 'Технолог литейного производства',
            image: teamMemberImage6
        },
        {
            name: 'Владислав Усков',
            position: 'Технолог механической обработки',
            image: teamMemberImage7
        },
    ]

    let settings = {
        infinite: true,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        afterChange: (data: number) => {
            setActiveSlider(data + 1)
        },

        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    };

    return <section className={`our-team-section ${ isVisibleSection ? 'show-section' : ''}`} id={'our-team'} ref={sectionRef}>
        <Stack className={'G-container'} width={"fill"}>
            <Stack className={'our-team-content'} direction={"row"} gap={30} width={"fill"} justify={"space-between"}>
                <div className={'our-team-info'}>
                    <Stack className={'our-team-title'}>
                        <h3>Наша команда</h3>
                        <p>Действуем быстро и эффективно.</p>
                    </Stack>
                    {window.innerWidth>991?       <Stack className={'slider-options'}>
                        <Stack className={'slider-counter'} direction={"row"} align={"end"}>
                            <h3>0{activeSlider}</h3>
                            <p>/ 0{ourTeamList.length}</p>
                        </Stack>
                        <Stack direction={"row"} gap={15}>
                            <div
                                onClick={() => {
                                    slider?.current?.slickPrev()
                                }}
                                className={'arrow-right'}
                            ><IconArrowSliderLeft size={16}/></div>
                            <div onClick={() => {
                                slider?.current?.slickNext()
                            }}
                                 className={'arrow-left'}
                            >
                                <IconArrowSliderRight size={16}/>
                            </div>

                        </Stack>
                    </Stack>:null}
                </div>
                <div className={'our-team-slider'}>
                    <Slider {...settings} ref={slider}>
                        {ourTeamList.map((item, index) => {
                            return <div className={'team-member-content'} key={index}>
                                <Stack className={'team-member-item'}>
                                    <img src={item.image} alt="team-member"/>
                                    <h3>{item.name}</h3>
                                    <p>{item.position}</p>
                                </Stack>
                            </div>
                        })}
                    </Slider>
                </div>
                {window.innerWidth<991?       <Stack className={'slider-options'}>
                    <Stack className={'slider-counter'} direction={"row"} align={"end"}>
                        <h3>0{activeSlider}</h3>
                        <p>/ 0{ourTeamList.length}</p>
                    </Stack>
                    <Stack direction={"row"} gap={15}>
                        <div
                            onClick={() => {
                                slider?.current?.slickPrev()
                            }}
                            className={'arrow-right'}
                        ><IconArrowSliderLeft size={16}/></div>
                        <div onClick={() => {
                            slider?.current?.slickNext()
                        }}
                             className={'arrow-left'}
                        >
                            <IconArrowSliderRight size={16}/>
                        </div>

                    </Stack>
                </Stack>: null}
            </Stack>
        </Stack>
    </section>
}





